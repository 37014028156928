import React, { useRef, useEffect } from 'react';

interface InvoicerDetailsProps {
  invoicerName: string;
  invoicerAddress: string;
  onNameChange: (value: string) => void;
  onAddressChange: (value: string) => void;
}

const InvoicerDetails: React.FC<InvoicerDetailsProps> = ({
  invoicerName,
  invoicerAddress,
  onNameChange,
  onAddressChange
}) => {
  const nameInputRef = useRef<HTMLInputElement>(null);
  const addressInputRef = useRef<HTMLInputElement>(null);

  // Update refs when props change
  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.value = invoicerName;
    }
    if (addressInputRef.current) {
      addressInputRef.current.value = invoicerAddress;
    }
  }, [invoicerName, invoicerAddress]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (nameInputRef.current) {
      nameInputRef.current.value = value;
    }
    onNameChange(value);
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (addressInputRef.current) {
      addressInputRef.current.value = value;
    }
    onAddressChange(value);
  };

  return (
    <div className="section">
      <h2>Invoicer Details</h2>
      <div className="form-group">
        <label>Your Name:</label>
        <input 
          ref={nameInputRef}
          type="text" 
          defaultValue={invoicerName}
          onChange={handleNameChange}
          placeholder="Enter your name"
        />
      </div>
      <div className="form-group">
        <label>Your Address:</label>
        <input 
          ref={addressInputRef}
          type="text"
          defaultValue={invoicerAddress}
          onChange={handleAddressChange}
          placeholder="Enter your address"
        />
      </div>
    </div>
  );
};

export default InvoicerDetails;
