import React from 'react';
import { ExtraCostItem } from '../../shared/types';

interface CostDetailsProps {
  hours: string;
  minutes: string;
  hourlyRate: string;
  extraCosts: ExtraCostItem[];
  rsuPercentage: string;
  rsuBalanceBeforeTransfer: string;
  singleLineCostMode?: boolean;
  onHoursChange: (value: string) => void;
  onMinutesChange: (value: string) => void;
  onHourlyRateChange: (value: string) => void;
  onExtraCostsChange: (items: ExtraCostItem[]) => void;
  onRsuPercentageChange: (value: string) => void;
  onRsuBalanceBeforeTransferChange: (value: string) => void;
  onSingleLineCostModeChange: (value: boolean) => void;
}

const CostDetails: React.FC<CostDetailsProps> = ({
  hours,
  minutes,
  hourlyRate,
  extraCosts,
  rsuPercentage,
  rsuBalanceBeforeTransfer,
  singleLineCostMode = false,
  onHoursChange,
  onMinutesChange,
  onHourlyRateChange,
  onExtraCostsChange,
  onRsuPercentageChange,
  onRsuBalanceBeforeTransferChange,
  onSingleLineCostModeChange
}) => {
  const handleAddExtraCost = () => {
    onExtraCostsChange([...extraCosts, { title: '', amount: 0 }]);
  };

  const handleRemoveExtraCost = (index: number) => {
    const newExtraCosts = extraCosts.filter((_, i) => i !== index);
    onExtraCostsChange(newExtraCosts);
  };

  const handleExtraCostChange = (index: number, field: 'title' | 'amount', value: string) => {
    const newExtraCosts = extraCosts.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [field]: field === 'amount' ? parseFloat(value) || 0 : value
        };
      }
      return item;
    });
    onExtraCostsChange(newExtraCosts);
  };

  return (
    <div className="section">
      <h2>Cost Details</h2>
      <div className="form-group time-input">
        <label>Time Worked:</label>
        <div className="time-fields">
          <div className="time-field">
            <input 
              type="number" 
              value={hours} 
              onChange={(e) => onHoursChange(e.target.value)} 
              placeholder="Hours"
              min="0"
            />
            <span>hrs</span>
          </div>
          <div className="time-field">
            <input 
              type="number" 
              value={minutes} 
              onChange={(e) => onMinutesChange(e.target.value)} 
              placeholder="Minutes"
              min="0"
              max="59"
            />
            <span>min</span>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label>Hourly Rate:</label>
        <input 
          type="number" 
          value={hourlyRate} 
          onChange={(e) => onHourlyRateChange(e.target.value)} 
          placeholder="Enter hourly rate"
        />
      </div>
      
      <div className="extra-costs-section">
        <div className="extra-costs-header">
          <h3>Extra Costs</h3>
          <div className="extra-costs-controls">
            <button 
              type="button" 
              onClick={handleAddExtraCost}
              className="add-cost-btn"
            >
              + Add Cost
            </button>
            <div className="toggle-switch">
              <label>
                <input
                  type="checkbox"
                  checked={singleLineCostMode}
                  onChange={(e) => onSingleLineCostModeChange(e.target.checked)}
                />
                <span className="slider"></span>
                <span className="toggle-label">Single Line Mode</span>
              </label>
            </div>
          </div>
        </div>
        
        {extraCosts.map((item, index) => (
          <div key={index} className="extra-cost-item">
            <input
              type="text"
              value={item.title}
              onChange={(e) => handleExtraCostChange(index, 'title', e.target.value)}
              placeholder="Cost title"
              className="extra-cost-title"
            />
            <input
              type="number"
              value={item.amount}
              onChange={(e) => handleExtraCostChange(index, 'amount', e.target.value)}
              placeholder="Amount"
              className="extra-cost-amount"
            />
            <button
              type="button"
              onClick={() => handleRemoveExtraCost(index)}
              className="remove-cost-btn"
            >
              ×
            </button>
          </div>
        ))}
      </div>

      <div className="rsu-section">
        <div className="form-group">
          <label>RSU Conversion Percentage:</label>
          <input 
            type="number" 
            value={rsuPercentage} 
            onChange={(e) => onRsuPercentageChange(e.target.value)} 
            placeholder="Enter RSU percentage"
          />
        </div>
        
        {rsuPercentage && parseFloat(rsuPercentage) > 0 && (
          <div className="form-group">
            <label>Previous RSU Balance:</label>
            <input 
              type="number" 
              value={rsuBalanceBeforeTransfer} 
              onChange={(e) => onRsuBalanceBeforeTransferChange(e.target.value)} 
              placeholder="Enter previous RSU balance"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CostDetails;
