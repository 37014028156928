import React from 'react';

interface NotesProps {
  message: string;
  seasonalGraphic: 'winter' | 'nye' | null;
  onMessageChange: (value: string) => void;
  onSeasonalGraphicChange: (value: 'winter' | 'nye' | null) => void;
}

const Notes: React.FC<NotesProps> = ({
  message,
  seasonalGraphic,
  onMessageChange,
  onSeasonalGraphicChange
}) => {
  return (
    <div className="section">
      <h2>Additional Notes</h2>
      <div className="form-group">
        <label>Seasonal Graphic:</label>
        <select
          value={seasonalGraphic || ''}
          onChange={(e) => onSeasonalGraphicChange(e.target.value as 'winter' | 'nye' | null)}
          style={{ width: '100%', padding: '8px', marginBottom: '15px' }}
        >
          <option value="">None</option>
          <option value="winter">Winter Theme</option>
          <option value="nye">New Year's Eve Theme</option>
        </select>
      </div>
      <div className="form-group">
        <label>Message (Optional):</label>
        <textarea
          value={message}
          onChange={(e) => onMessageChange(e.target.value)}
          placeholder="Enter an optional message to display at the bottom of the invoice"
          rows={4}
          style={{ width: '100%', resize: 'vertical' }}
        />
      </div>
    </div>
  );
};

export default Notes;
