import { InvoiceSettings } from '../../shared/types';
import { calculateTotal } from './calculations';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

// Import fonts
import nexaHeavyFont from '../assets/fonts/Nexa-Heavy.ttf';
import verdanaRegularFont from '../assets/fonts/verdana.ttf';
import verdanaBoldFont from '../assets/fonts/verdana-bold.ttf';

const formatDate = (dateStr: string): string => {
  return dateStr.replace(/-/g, '/');
};

// Convert ArrayBuffer to binary string in chunks to avoid stack overflow
const arrayBufferToBinary = (buffer: ArrayBuffer): string => {
  const bytes = new Uint8Array(buffer);
  let binary = '';
  const chunkSize = 8192;
  
  for (let i = 0; i < bytes.length; i += chunkSize) {
    const chunk = bytes.slice(i, i + chunkSize);
    binary += String.fromCharCode.apply(null, chunk as unknown as number[]);
  }
  
  return binary;
};

const getMonthName = (date: Date): string => {
  return date.toLocaleString('en-US', { month: 'long' });
};

const generateFileName = (settings: InvoiceSettings): string => {
  const startDate = new Date(settings.startDate);
  const year = startDate.getFullYear();
  const month = String(startDate.getMonth() + 1).padStart(2, '0');
  const monthName = getMonthName(startDate);
  
  return `${year}-${month} ${settings.invoicerName} Hourly Invoice ${settings.clientName} ${monthName}${year}`;
};

export const generateInvoicePDF = async (settings: InvoiceSettings): Promise<void> => {
  const { laborCost, extra, totalCost, rsuAmount } = calculateTotal(settings);

  // Create new jsPDF instance
  const doc = new jsPDF();

  try {
    // Load fonts using fetch since webpack will handle the URLs
    const [nexaHeavy, verdanaRegular, verdanaBold] = await Promise.all([
      fetch(nexaHeavyFont).then(r => r.arrayBuffer()),
      fetch(verdanaRegularFont).then(r => r.arrayBuffer()),
      fetch(verdanaBoldFont).then(r => r.arrayBuffer())
    ]);

    // Convert ArrayBuffers to binary strings
    const nexaHeavyBinary = arrayBufferToBinary(nexaHeavy);
    const verdanaRegularBinary = arrayBufferToBinary(verdanaRegular);
    const verdanaBoldBinary = arrayBufferToBinary(verdanaBold);

    // Add fonts to PDF
    doc.addFileToVFS('Nexa-Heavy.ttf', nexaHeavyBinary);
    doc.addFileToVFS('verdana.ttf', verdanaRegularBinary);
    doc.addFileToVFS('verdana-bold.ttf', verdanaBoldBinary);

    doc.addFont('Nexa-Heavy.ttf', 'Nexa', 'bold');
    doc.addFont('verdana.ttf', 'Verdana', 'normal');
    doc.addFont('verdana-bold.ttf', 'Verdana', 'bold');

    // Title - INVOICE
    doc.setFontSize(15);
    doc.setFont('Nexa', 'bold');
    doc.text('INVOICE', 20, 15);

    // Invoicer details
    doc.setFontSize(10);
    doc.setFont('Nexa', 'bold');
    doc.text(settings.invoicerName, 120, 15);
    doc.setFontSize(8);
    doc.setFont('Verdana', 'normal');
    doc.text(settings.invoicerAddress.split('\n'), 120, 20);

    // Invoice details
    doc.setFontSize(8);
    doc.text(`Start Date: ${formatDate(settings.startDate)}`, 120, 30);
    doc.text(`End Date: ${formatDate(settings.endDate)}`, 120, 35);
    doc.text('Services Provided: ' + settings.serviceDescription, 120, 40, {
      maxWidth: 70,
      lineHeightFactor: 1
    });

    // Billed To section
    doc.setFontSize(10);
    doc.setFont('Nexa', 'bold');
    doc.text('Billed To:', 20, 25);
    doc.setFont('Verdana', 'normal');
    doc.setFontSize(8);
    doc.text(settings.clientName, 20, 30);
    doc.text(settings.clientAddress.split('\n'), 20, 35);
    doc.text(`EIN: ${settings.clientEIN}`, 20, 40);

    // Timesheet Link section
    doc.setFontSize(10);
    doc.setFont('Nexa', 'bold');
    doc.text('Link To Timesheet:', 20, 51);
    doc.setFont('Verdana', 'normal');
    doc.setFontSize(8);
    
    if (settings.timesheetUrl) {
      doc.setTextColor(0, 0, 255);
      doc.textWithLink(settings.timesheetUrl, 20, 56, {
        url: settings.timesheetUrl
      });
      doc.setTextColor(0, 0, 0);
    } else {
      doc.text('N/A', 20, 56);
    }

    // Cost Breakdown Section
    const startY = 78;
    const lineHeight = 5;
    
    // Draw header
    doc.setFontSize(11);
    doc.setFont('Nexa', 'bold');
    doc.text('Cost Breakdown', 20, startY - 9);

    // Table headers
    doc.setFontSize(9);
    doc.setFont('Nexa', 'bold');
    
    // Define column positions
    const col1X = 20;  // Service Description
    const col2X = 123; // Hours
    const col3X = 145; // Hourly Rate
    const col4X = 165; // Total Amount
    
    // Draw table header
    let currentY = startY;
    doc.text('Service Description', col1X, currentY);
    doc.text('Hours', col2X, currentY);
    doc.text('Rate', col3X, currentY);
    doc.text('Total', col4X, currentY);
    
    // Draw header line
    currentY += 3;
    doc.setLineWidth(0.1);
    doc.line(20, currentY, 190, currentY);
    
    // Table content
    currentY += 5;
    doc.setFont('Verdana', 'normal');
    doc.setFontSize(8);
    
    // Format service description with period
    const serviceDesc = `${settings.serviceDescription} for period: ${formatDate(settings.startDate)} - ${formatDate(settings.endDate)}`;
    
    // Draw wrapped service description
    const splitDesc = doc.splitTextToSize(serviceDesc, 95);
    doc.text(splitDesc, col1X, currentY);
    
    // Calculate the maximum height needed for the row
    const textHeight = splitDesc.length * 4;
    const rowHeight = Math.max(textHeight, 6);
    
    // Draw other columns
    doc.text(settings.hours, col2X, currentY);
    doc.text(`$${settings.hourlyRate}`, col3X, currentY);
    doc.text(`$${laborCost.toFixed(2)}`, col4X, currentY);
    
    // Draw row line
    currentY += rowHeight - 2;
    doc.setLineWidth(0.1);
    doc.line(20, currentY, 190, currentY);
    
    currentY += 8;  // Gap between tables

    // Extra Costs Table
    if (settings.extraCosts.length > 0) {
      // Table header
      doc.setFontSize(9);
      doc.setFont('Nexa', 'bold');
      doc.text('Extra Item/Service Cost', 20, currentY);
      
      // Draw header line
      currentY += 3;
      doc.setLineWidth(0.1);
      doc.line(20, currentY, 190, currentY);
      
      // Table content
      currentY += 5;  // Space after header line
      doc.setFont('Verdana', 'normal');
      doc.setFontSize(8);

      settings.extraCosts.forEach((item, index) => {
        doc.text(item.title, 20, currentY);
        doc.text(`$${item.amount.toFixed(2)}`, 165, currentY);
        currentY += 3;  // Space between content and bottom line
        
        // Draw line after each item
        doc.setLineWidth(0.1);
        doc.line(20, currentY, 190, currentY);
        currentY += 5;  // Space after line before next item
      });

      currentY += 3;
    }

    // Grand Total section
    doc.setLineWidth(0.5);
    doc.line(20, currentY, 190, currentY);
    currentY += lineHeight;

    doc.setFont('Nexa', 'bold');
    doc.text('Extra Costs Total:', 20, currentY);
    doc.text(`$${extra.toFixed(2)}`, 165, currentY);
    currentY += lineHeight;

    doc.text('Main Service Total Cost:', 20, currentY);
    doc.text(`$${(laborCost + extra).toFixed(2)}`, 165, currentY);
    currentY += lineHeight;

    // Draw thick line before final total
    doc.setLineWidth(0.5);
    doc.line(20, currentY, 190, currentY);
    currentY += lineHeight;

    doc.setFontSize(10);
    doc.text('TOTAL:', 20, currentY);
    doc.text(`$${totalCost.toFixed(2)}`, 165, currentY);
    currentY += lineHeight;

    // RSU Conversion
    doc.setFontSize(9);
    doc.text(`RSU Conversion (${settings.rsuPercentage}%):`, 20, currentY);
    doc.text(`$${rsuAmount.toFixed(2)}`, 165, currentY);

    // Save the PDF with the generated filename
    doc.save(`${generateFileName(settings)}.pdf`);
  } catch (error: any) {
    console.error('Error loading fonts:', error);
    throw new Error(`Error loading fonts: ${error.message}`);
  }
};
