import React from 'react';

interface ClientDetailsProps {
  clientName: string;
  clientAddress: string;
  clientEIN: string;
  timesheetUrl: string;
  onNameChange: (value: string) => void;
  onAddressChange: (value: string) => void;
  onEINChange: (value: string) => void;
  onTimesheetUrlChange: (value: string) => void;
}

const ClientDetails: React.FC<ClientDetailsProps> = ({
  clientName,
  clientAddress,
  clientEIN,
  timesheetUrl,
  onNameChange,
  onAddressChange,
  onEINChange,
  onTimesheetUrlChange
}) => {
  return (
    <div className="section">
      <h2>Client Details</h2>
      <div className="form-group">
        <label>Client Name:</label>
        <input 
          type="text" 
          value={clientName} 
          onChange={(e) => onNameChange(e.target.value)} 
          placeholder="Enter client name"
        />
      </div>
      <div className="form-group">
        <label>Client Address:</label>
        <input 
          type="text"
          value={clientAddress} 
          onChange={(e) => onAddressChange(e.target.value)} 
          placeholder="Enter client address"
        />
      </div>
      <div className="form-group">
        <label>Client EIN:</label>
        <input 
          type="text" 
          value={clientEIN} 
          onChange={(e) => onEINChange(e.target.value)} 
          placeholder="Enter client EIN"
        />
      </div>
      <div className="form-group">
        <label>Timesheet URL:</label>
        <input 
          type="url" 
          value={timesheetUrl} 
          onChange={(e) => onTimesheetUrlChange(e.target.value)} 
          placeholder="Enter timesheet URL"
        />
      </div>
    </div>
  );
};

export default ClientDetails;
