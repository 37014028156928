import React from 'react';

interface InvoicePeriodProps {
  startDate: string;
  endDate: string;
  serviceDescription: string;
  onStartDateChange: (value: string) => void;
  onEndDateChange: (value: string) => void;
  onDescriptionChange: (value: string) => void;
}

const InvoicePeriod: React.FC<InvoicePeriodProps> = ({
  startDate,
  endDate,
  serviceDescription,
  onStartDateChange,
  onEndDateChange,
  onDescriptionChange
}) => {
  return (
    <div className="section">
      <h2>Invoice Period</h2>
      <div className="form-group">
        <label>Start Date:</label>
        <input 
          type="date" 
          value={startDate} 
          onChange={(e) => onStartDateChange(e.target.value)} 
        />
      </div>
      <div className="form-group">
        <label>End Date:</label>
        <input 
          type="date" 
          value={endDate} 
          onChange={(e) => onEndDateChange(e.target.value)} 
        />
      </div>
      <div className="form-group">
        <label>Service Description:</label>
        <textarea 
          value={serviceDescription} 
          onChange={(e) => onDescriptionChange(e.target.value)} 
          placeholder="Enter description of services provided"
          rows={3}
        />
      </div>
    </div>
  );
};

export default InvoicePeriod;
