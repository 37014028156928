import { InvoiceSettings } from '../../shared/types';

export const saveSettings = (settings: InvoiceSettings): void => {
  try {
    localStorage.setItem('invoiceSettings', JSON.stringify(settings));
  } catch (error) {
    console.error('Error saving settings:', error);
    throw new Error('Failed to save settings');
  }
};

export const loadSettings = (): InvoiceSettings | null => {
  try {
    const savedSettings = localStorage.getItem('invoiceSettings');
    if (!savedSettings) {
      return null;
    }
    return JSON.parse(savedSettings);
  } catch (error) {
    console.error('Error loading settings:', error);
    throw new Error('Failed to load settings');
  }
};
