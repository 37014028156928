import React from 'react';

const TitleBar: React.FC = () => {
  return (
    <div className="titlebar">
      <div className="titlebar-content">
        <div className="window-title">Invoice Generator</div>
      </div>
    </div>
  );
};

export default TitleBar;
