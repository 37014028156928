import { InvoiceSettings, CostDetails } from '../../shared/types';

export const calculateTotal = (settings: InvoiceSettings): CostDetails => {
  const totalHours = parseFloat(settings.hours) || 0;
  const rate = parseFloat(settings.hourlyRate) || 0;
  const extra = settings.extraCosts.reduce((sum, item) => sum + item.amount, 0);
  const laborCost = totalHours * rate;
  const totalCost = laborCost + extra;
  const rsuAmount = totalCost * ((parseFloat(settings.rsuPercentage) || 0) / 100);

  return {
    laborCost,
    extra,
    totalCost,
    rsuAmount
  };
};
