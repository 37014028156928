import React, { useState, useCallback } from 'react';
import './App.css';
import { InvoiceSettings, ExtraCostItem } from '../shared/types';
import { generateInvoicePDF } from './utils/pdfGenerator';
import { saveSettings, loadSettings } from './utils/settings';
import TitleBar from './components/TitleBar';
import InvoicerDetails from './components/InvoicerDetails';
import ClientDetails from './components/ClientDetails';
import InvoicePeriod from './components/InvoicePeriod';
import CostDetails from './components/CostDetails';
import Toast from './components/Toast';

interface ToastState {
  message: string;
  type: 'success' | 'error';
  visible: boolean;
}

const App: React.FC = () => {
  const [invoicerName, setInvoicerName] = useState('');
  const [invoicerAddress, setInvoicerAddress] = useState('');
  const [clientName, setClientName] = useState('');
  const [clientAddress, setClientAddress] = useState('');
  const [clientEIN, setClientEIN] = useState('');
  const [timesheetUrl, setTimesheetUrl] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [serviceDescription, setServiceDescription] = useState('');
  const [hours, setHours] = useState('');
  const [hourlyRate, setHourlyRate] = useState('');
  const [extraCosts, setExtraCosts] = useState<ExtraCostItem[]>([]);
  const [rsuPercentage, setRsuPercentage] = useState('');
  const [toast, setToast] = useState<ToastState>({
    message: '',
    type: 'success',
    visible: false
  });

  const showToast = (message: string, type: 'success' | 'error' = 'success') => {
    setToast({ message, type, visible: true });
  };

  const hideToast = () => {
    setToast(prev => ({ ...prev, visible: false }));
  };

  const handleInvoicerNameChange = useCallback((value: string) => {
    setInvoicerName(value);
  }, []);

  const handleInvoicerAddressChange = useCallback((value: string) => {
    setInvoicerAddress(value);
  }, []);

  const handleClientNameChange = useCallback((value: string) => {
    setClientName(value);
  }, []);

  const handleClientAddressChange = useCallback((value: string) => {
    setClientAddress(value);
  }, []);

  const handleClientEINChange = useCallback((value: string) => {
    setClientEIN(value);
  }, []);

  const handleTimesheetUrlChange = useCallback((value: string) => {
    setTimesheetUrl(value);
  }, []);

  const handleStartDateChange = useCallback((value: string) => {
    setStartDate(value);
  }, []);

  const handleEndDateChange = useCallback((value: string) => {
    setEndDate(value);
  }, []);

  const handleServiceDescriptionChange = useCallback((value: string) => {
    setServiceDescription(value);
  }, []);

  const handleHoursChange = useCallback((value: string) => {
    setHours(value);
  }, []);

  const handleHourlyRateChange = useCallback((value: string) => {
    setHourlyRate(value);
  }, []);

  const handleExtraCostsChange = useCallback((items: ExtraCostItem[]) => {
    setExtraCosts(items);
  }, []);

  const handleRsuPercentageChange = useCallback((value: string) => {
    setRsuPercentage(value);
  }, []);

  const handleSaveSettings = useCallback(() => {
    try {
      const settings: InvoiceSettings = {
        invoicerName,
        invoicerAddress,
        clientName,
        clientAddress,
        clientEIN,
        timesheetUrl,
        startDate,
        endDate,
        serviceDescription,
        hours,
        hourlyRate,
        extraCosts,
        rsuPercentage
      };
      saveSettings(settings);
      showToast('Settings saved successfully!');
    } catch (error) {
      console.error('Error saving settings:', error);
      showToast('Failed to save settings. Please try again.', 'error');
    }
  }, [
    invoicerName, invoicerAddress, clientName, clientAddress, clientEIN,
    timesheetUrl, startDate, endDate, serviceDescription, hours, hourlyRate,
    extraCosts, rsuPercentage
  ]);

  const handleLoadSettings = useCallback(() => {
    try {
      const settings = loadSettings();
      if (!settings) {
        showToast('No saved settings found.', 'error');
        return;
      }

      setInvoicerName(settings.invoicerName || '');
      setInvoicerAddress(settings.invoicerAddress || '');
      setClientName(settings.clientName || '');
      setClientAddress(settings.clientAddress || '');
      setClientEIN(settings.clientEIN || '');
      setTimesheetUrl(settings.timesheetUrl || '');
      setStartDate(settings.startDate || '');
      setEndDate(settings.endDate || '');
      setServiceDescription(settings.serviceDescription || '');
      setHours(settings.hours || '');
      setHourlyRate(settings.hourlyRate || '');
      setExtraCosts(Array.isArray(settings.extraCosts) ? settings.extraCosts : []);
      setRsuPercentage(settings.rsuPercentage || '');

      showToast('Settings loaded successfully!');
    } catch (error) {
      console.error('Error loading settings:', error);
      showToast('Failed to load settings. Please try again.', 'error');
    }
  }, []);

  const handleGeneratePDF = useCallback(() => {
    try {
      const settings: InvoiceSettings = {
        invoicerName,
        invoicerAddress,
        clientName,
        clientAddress,
        clientEIN,
        timesheetUrl,
        startDate,
        endDate,
        serviceDescription,
        hours,
        hourlyRate,
        extraCosts,
        rsuPercentage
      };
      generateInvoicePDF(settings);
      showToast('PDF generated successfully!');
    } catch (error) {
      console.error('Error generating PDF:', error);
      showToast('Failed to generate PDF. Please try again.', 'error');
    }
  }, [
    invoicerName, invoicerAddress, clientName, clientAddress, clientEIN,
    timesheetUrl, startDate, endDate, serviceDescription, hours, hourlyRate,
    extraCosts, rsuPercentage
  ]);

  return (
    <>
      <TitleBar />
      <div className="container">
        <h1>Invoice Generator</h1>
        
        <div className="button-group">
          <button onClick={handleSaveSettings} className="secondary-button">Save Settings</button>
          <button onClick={handleLoadSettings} className="secondary-button">Load Settings</button>
        </div>

        <InvoicerDetails
          invoicerName={invoicerName}
          invoicerAddress={invoicerAddress}
          onNameChange={handleInvoicerNameChange}
          onAddressChange={handleInvoicerAddressChange}
        />

        <ClientDetails
          clientName={clientName}
          clientAddress={clientAddress}
          clientEIN={clientEIN}
          timesheetUrl={timesheetUrl}
          onNameChange={handleClientNameChange}
          onAddressChange={handleClientAddressChange}
          onEINChange={handleClientEINChange}
          onTimesheetUrlChange={handleTimesheetUrlChange}
        />

        <InvoicePeriod
          startDate={startDate}
          endDate={endDate}
          serviceDescription={serviceDescription}
          onStartDateChange={handleStartDateChange}
          onEndDateChange={handleEndDateChange}
          onDescriptionChange={handleServiceDescriptionChange}
        />

        <CostDetails
          hours={hours}
          hourlyRate={hourlyRate}
          extraCosts={extraCosts}
          rsuPercentage={rsuPercentage}
          onHoursChange={handleHoursChange}
          onHourlyRateChange={handleHourlyRateChange}
          onExtraCostsChange={handleExtraCostsChange}
          onRsuPercentageChange={handleRsuPercentageChange}
        />

        <button onClick={handleGeneratePDF} className="primary-button">Generate Invoice PDF</button>

        {toast.visible && (
          <Toast
            message={toast.message}
            type={toast.type}
            onClose={hideToast}
          />
        )}
      </div>
    </>
  );
};

export default App;
